body#factom {
  font-family: Montserrat, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #333333; }
  body#factom h1,
  body#factom h2,
  body#factom h3,
  body#factom h4,
  body#factom h5,
  body#factom label,
  body#factom footer {
    font-family: Montserrat, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.container .container-app .content-login .right-panel h1 {
  text-align: center;
  font-size: 24px;
  color: #388fc9;
  font-weight: 500; }

body {
  background: linear-gradient(0.5deg, #003862 0%, #1B75BC 100%); }

.container {
  background-color: transparent;
  padding-top: 75px;
  width: 85%; }
  @media only screen and (max-width: 768px) {
    .container {
      width: 95%;
      padding: 30px 15px 0 15px; } }
  .container .container-app {
    background-color: #fff;
    width: 100%;
    height: auto; }
    .container .container-app p {
      margin: 0 0 20px 0; }
    .container .container-app .hide-on-med-and-down {
      padding-right: 15px; }
    .container .container-app .content-login {
      font-size: 13px; }
      .container .container-app .content-login h1 {
        font-size: 36px; }
      .container .container-app .content-login .left-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 45px;
        height: 100%; }
        .container .container-app .content-login .left-panel.login {
          min-height: 670px; }
        .container .container-app .content-login .left-panel.signup-panel {
          min-height: 800px; }
        .container .container-app .content-login .left-panel p {
          font-size: 15px;
          line-height: 20px; }
          .container .container-app .content-login .left-panel p:first-of-type {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 25px; }
        .container .container-app .content-login .left-panel .text-bottom {
          font-size: 0.9rem;
          background-color: #F2F7FC;
          border-radius: 2px;
          padding: 11px;
          bottom: 0; }
          .container .container-app .content-login .left-panel .text-bottom a {
            color: #388fc9; }
        .container .container-app .content-login .left-panel .number {
          display: inline-block;
          border-radius: 50%;
          background-color: #125afa;
          height: 33px;
          width: 33px;
          line-height: 33px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          text-align: center; }
        .container .container-app .content-login .left-panel .instructions-container {
          margin-top: 50px; }
          .container .container-app .content-login .left-panel .instructions-container .instructions .col p {
            line-height: 19px;
            margin-left: 0.6rem;
            font-size: 1rem; }
      .container .container-app .content-login .right-panel {
        padding: 45px;
        background-color: #F2F7FC; }
        @media only screen and (max-width: 600px) {
          .container .container-app .content-login .right-panel {
            padding: 45px 0; } }
        .container .container-app .content-login .right-panel.signup-panel h1 {
          margin-bottom: 30px; }
        .container .container-app .content-login .right-panel.signup-panel .form-cta .custom-align-row {
          justify-content: space-between; }
        .container .container-app .content-login .right-panel.signup-panel .tos-privacy a {
          color: #388fc9; }
        .container .container-app .content-login .right-panel .form-container {
          max-width: 400px;
          margin: 0 auto; }
          @media only screen and (max-width: 768px) {
            .container .container-app .content-login .right-panel .form-container {
              max-width: none; } }
        .container .container-app .content-login .right-panel .forgot-password {
          font-weight: 500;
          margin-left: 36px; }
        .container .container-app .content-login .right-panel .button-container {
          margin-top: 50px; }
          @media only screen and (max-width: 600px) {
            .container .container-app .content-login .right-panel .button-container {
              margin-top: 30px; } }
        .container .container-app .content-login .right-panel .form-cta p {
          margin: 0; }
          .container .container-app .content-login .right-panel .form-cta p:first-of-type {
            font-size: 1.1rem;
            line-height: 2rem; }
        .container .container-app .content-login .right-panel .form-cta .signup-text {
          margin-bottom: 30px; }
          @media only screen and (max-width: 600px) {
            .container .container-app .content-login .right-panel .form-cta .signup-text :nth-child(2) {
              padding-top: 15px; } }
        .container .container-app .content-login .right-panel .form-cta .signup-btn {
          padding-right: 0; }

.left-header {
  height: 130px;
  padding-top: 30px; }

.logo {
  margin-bottom: 47px; }
  .logo img {
    height: 47px;
    width: 65%; }

.left-header {
  display: flex;
  align-items: center;
  margin-bottom: 34px; }
  .left-header h1 {
    padding: 0;
    margin: 0;
    line-height: 1.5em; }

ul.list-auth-provider {
  padding: 0;
  border-bottom: none; }

.panel-body {
  padding: 0; }
  @media only screen and (min-width: 769px) {
    .panel-body {
      padding: 0 15px; } }
  @media only screen and (min-width: 751px) and (max-width: 992px) {
    .panel-body {
      padding: 0 80px; } }

.input-field .prefix {
  font-size: 1.2rem;
  top: 0.8rem;
  color: #388fc9; }
  .input-field .prefix.no-top {
    top: 0; }

.preloaded {
  margin-left: 36px;
  word-break: break-all; }

.panel-body .row {
  margin-bottom: 0px; }

.input-field label {
  text-transform: none;
  font-size: 1rem; }

.input-field .prefix.active {
  color: #388fc9; }

.btn-outline {
  width: 100%;
  line-height: 30px;
  background: transparent;
  display: flex;
  justify-content: center;
  box-shadow: none; }

.panel-body .btn-outline:hover {
  background: #125afa;
  border: 2px solid #125afa;
  color: #fff;
  text-decoration: none;
  box-shadow: none; }

.panel-body .link-primary {
  color: #388fc9;
  text-decoration: none; }
  .panel-body .link-primary:hover {
    color: #125afa;
    text-decoration: none; }

.panel-body.welcome-text {
  color: #388fc9;
  word-break: break-word; }

.btn-secondary {
  width: 100%;
  background: #E8B521;
  color: #fff;
  box-shadow: none; }

.panel-body .btn-secondary:hover {
  background: #C8980F;
  color: #fff;
  text-decoration: none;
  box-shadow: none; }

.btn.btn-primary {
  width: 100%;
  box-shadow: none; }
  .btn.btn-primary:hover {
    box-shadow: none; }

.btn {
  letter-spacing: 0;
  text-transform: none;
  font-size: 13px;
  font-weight: 600;
  font-style: normal; }

.panel-body .btn-outline {
  margin-top: 11px; }

@media only screen and (max-width: 600px) {
  .panel-body {
    padding: 0 30px; } }

.no-padding {
  padding: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eee7e7;
  margin: 30px 0;
  padding: 0; }

.hidden-div {
  display: none; }

.has-error span {
  color: red; }

.input-field .row .col.s12 {
  padding: 0px; }

.input-field .prefix ~ input {
  margin-left: 2.3rem; }

.input-field .inline-errors {
  margin-left: 2.6rem; }

.input-field.col label {
  left: 0; }

input[type="text"]:not(.browser-default) {
  font-size: 13px; }

.input-field > label:not(.label-icon).active {
  transform: translateY(-1px) scale(0.8); }

.custom-margin-p p {
  margin: 0; }

.custom-align-row {
  display: flex;
  align-items: center;
  flex-direction: row; }
  @media only screen and (max-width: 768px) {
    .custom-align-row {
      text-align: center; } }

.full-width-logged-out-container {
  left: 200px;
  right: 200px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 600px) {
    .full-width-logged-out-container {
      padding: 0 15px 15px 15px; } }
  .full-width-logged-out-container img {
    margin-top: 31px;
    height: auto;
    width: 302px; }
    @media only screen and (max-width: 768px) {
      .full-width-logged-out-container img {
        width: 75%; } }
  .full-width-logged-out-container h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 45px 0;
    color: #388fc9; }
  .full-width-logged-out-container p {
    font-size: 22px; }
    @media only screen and (max-width: 992px) {
      .full-width-logged-out-container p {
        font-size: 15px; } }
    .full-width-logged-out-container p:last-of-type {
      font-size: 16px; }
      @media only screen and (max-width: 992px) {
        .full-width-logged-out-container p:last-of-type {
          font-size: 12px; } }
  @media only screen and (max-width: 992px) {
    .full-width-logged-out-container {
      left: 100px;
      right: 100px; } }
  @media only screen and (max-width: 600px) {
    .full-width-logged-out-container {
      left: 30px;
      right: 30px; } }

.padding-extra {
  padding: 30px; }

.container-reset-password .btn.btn-primary {
  width: 50%; }
  @media only screen and (max-width: 600px) {
    .container-reset-password .btn.btn-primary {
      width: 85%; } }

.container-reset-password h2 {
  margin: 60px 0 15px 0; }

.container-reset-password .help-block {
  padding-bottom: 30px; }

.tos-privacy {
  margin-top: 15px; }
  @media only screen and (max-width: 600px) {
    .tos-privacy {
      text-align: center; } }

.log-out-footer {
  padding: 0 30px 20px 30px;
  color: #fff;
  text-transform: none; }
  .log-out-footer a {
    color: #fff;
    padding-left: 40px; }
    @media only screen and (max-width: 600px) {
      .log-out-footer a {
        padding-left: 5px; } }
  .log-out-footer .link-group {
    text-align: right; }
    @media only screen and (max-width: 600px) {
      .log-out-footer .link-group {
        text-align: center;
        margin-top: 15px; } }
  @media only screen and (max-width: 600px) {
    .log-out-footer {
      justify-content: center; }
      .log-out-footer .copyright {
        display: flex;
        justify-content: center; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col {
  flex-preferred-size: 0;
  flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.btn-disabled {
  pointer-events: none;
  background-color: #E1E1E1 !important;
  color: #A2A2A2 !important;
  box-shadow: none; }

.btn-enabled {
  background-color: #125afa;
  color: #fff; }

#forgot-password-form {
  text-align: left;
  max-width: 430px;
  margin: 0 auto;
  padding: 0 15px 0; }
  #forgot-password-form .help-block {
    margin-left: 2.3rem; }
  #forgot-password-form .input-field .prefix ~ label {
    margin-left: 2.3rem; }

fieldset:first-of-type.threescale-recaptcha {
  padding: 0;
  margin: 0 auto 20px;
  border: none; }
  fieldset:first-of-type.threescale-recaptcha ol {
    padding: 0;
    margin: 0; }
  fieldset:first-of-type.threescale-recaptcha ul.errors {
    max-width: 300px; }
    fieldset:first-of-type.threescale-recaptcha ul.errors li {
      color: #ff0800;
      padding: 10px 0 0;
      font-weight: bold; }
